import React from "react";
import { Link } from "gatsby";
import { Container } from "semantic-ui-react";

import LayoutInner from "../components/layout/layout-inner";
import SEO from "../components/seo/seo";
import SecondaryNavBar from "../components/secondary-nav-bar/secondary-nav-bar";
import { INFO_EMAIL, SITE_HOST, SUPPORT_PHONE } from "../constants";

const Page = () => (
  <LayoutInner>
    <SEO
        title="Website Terms and Conditions"
        keywords={[`rocksteady`]}
        description="At Rocksteady, we care about how your personal information is used and shared. View our website terms and conditions."
    />
    <SecondaryNavBar
      product="generic"
      title="Terms &amp; Conditions"
      titlePath="/terms-conditions/"
      titleActive={true}
      links={[]}
    />
    <Container>
      <div className="policy-text tc-text">
        <h2 className="tc-title uc-title">Please read these Terms and Conditions carefully before using this Site</h2>
        <h4>What's in these terms?</h4>
      
        <p>
          These terms tell you the rules for using our website <a href="/">Rocksteady Music School Website</a> (our{' '}
          <strong>"Site"</strong>).
        </p>
        <p>
          Click on the links below to go straight to more information on each area:
        </p>

        <ul>
          <li><Link to='/terms-conditions#us'>Who we are and how to contact us</Link></li>
          <li><Link to='/terms-conditions#accept'>By using our Site you accept these terms</Link></li>
          <li><Link to='/terms-conditions#other'>There are other terms that may apply to you</Link></li>
          <li><Link to='/terms-conditions#change_terms'>We may make changes to these terms</Link></li>
          <li><Link to='/terms-conditions#change_site'>We may make changes to our Site</Link></li>
          <li><Link to='/terms-conditions#suspend'>We may suspend or withdraw our Site</Link></li>
          <li><Link to='/terms-conditions#transfer'>We may transfer this Agreement to someone else</Link></li>
          <li><Link to='/terms-conditions#uk'>Our Site is only for users in the UK</Link></li>
          <li><Link to='/terms-conditions#safe'>You must keep your account details safe</Link></li>
          <li><Link to='/terms-conditions#how'>How you may use material on our Site</Link></li>
          <li><Link to='/terms-conditions#information'>Do not rely on information on our Site</Link></li>
          <li><Link to='/terms-conditions#external'>We are not responsible for websites we link to</Link></li>
          <li><Link to='/terms-conditions#responsibility'>Our responsibility for loss or damage suffered by you</Link></li>
          <li><Link to='/terms-conditions#personal'>How we may use your personal information</Link></li>
          <li><Link to='/terms-conditions#virus'>We are not responsible for viruses and you must not introduce them</Link></li>
          <li><Link to='/terms-conditions#links'>Rules about linking to our Site</Link></li>
          <li><Link to='/terms-conditions#country'>Which country's laws apply to any disputes?</Link></li>
        </ul>

        <h2 id="us" className="tc-title uc-title">Who we are and how to contact Us</h2>
        <p>
          <a href="/">{`www.${SITE_HOST}`}</a> is a site operated by Rocksteady Music School Limited 
          (<strong>"We"</strong>). We are a limited company registered in England and Wales under company number 08111026 
          and have our registered office and main trading address at Bohunt Manor, Portsmouth Road, Liphook, Hampshire, 
          United Kingdom, GU30 7DL. Our VAT number is GB156252612.
        </p> 
        <p>
          To contact us, please email{' '}
          <a href={`mailto:${INFO_EMAIL}`}>
            {INFO_EMAIL}
          </a>
          {' '}or telephone us on {SUPPORT_PHONE}.
        </p>

        <h2 id="accept" className="tc-title uc-title">By using our Site you accept these Terms</h2>

        <p>By using our Site, you confirm that you accept these terms of use and that you agree to comply with them.</p>
        <p>If you do not agree to these terms, you must not use our Site.</p>
        <p>We recommend that you print a copy of these terms for future reference.</p>

        <h2 id="other" className="tc-title uc-title">There are Other Terms that may apply to You</h2>

        <p>These terms of use refer to the following additional terms, which also apply to your use of our Site:</p>
        <ul>
          <li>Our <a href="/privacy-policy/">Privacy Notice</a>. See further under <Link to='/terms-conditions#personal'>
            How we may use your personal information</Link></li>
          <li>Our <a href="/cookie-policy/">Cookie Policy</a>, which sets out information about the cookies on our Site.</li>
        </ul>  

        <h2 id="change_terms" className="tc-title uc-title">We may make changes to these Terms</h2>
        <p>
          We amend these terms from time to time. Every time you wish to use our Site, please check these terms to 
          ensure you understand the terms that apply at that time.
        </p>

        <h2 id="change_site" className="tc-title uc-title">We may make changes to our Site</h2>
        <p>
          We may update and change our Site from time to time to reflect changes to our services, our users' needs and 
          our business priorities.
        </p>

        <h2 id="suspend" className="tc-title uc-title">We may Suspend or Withdraw our Site</h2>
        <p>Our Site is made available free of charge.</p>
        <p>
          We do not guarantee that our Site, or any content on it, will always be available or be uninterrupted. 
          We may suspend or withdraw or restrict the availability of all or any part of our Site for business and 
          operational reasons. We will try to give you reasonable notice of any suspension or withdrawal.
        </p>
        <p>  
          You are also responsible for ensuring that all persons who access our Site through your internet connection 
          are aware of these terms of use and other applicable terms and conditions, and that they comply with them.
        </p>  

        <h2 id="transfer" className="tc-title uc-title">We may transfer this Agreement to Someone Else</h2>
        <p>
          We may transfer our rights and obligations under these terms to another organisation. We will always tell 
          you in writing if this happens and we will ensure that the transfer will not affect your rights under the 
          contract.
        </p>

        <h2 id="uk" className="tc-title uc-title">Our Site is Only for Users in the UK</h2>
        <p>
          Our Site is directed to people residing in the United Kingdom. We do not represent that content available on 
          or through our Site is appropriate for use or available in other locations.
        </p>

        <h2 id="safe" className="tc-title uc-title">You must keep Your Account Details Safe</h2>
        <p>
          If you choose, or you are provided with, a user identification code, password or any other piece of 
          information as part of our security procedures, you must treat such information as confidential. You must 
          not disclose it to any third party.
        </p>
        <p>  
          We have the right to disable any user identification code or password, whether chosen by you or allocated 
          by us, at any time, if in our reasonable opinion you have failed to comply with any of the provisions of 
          these terms of use.
        </p> 
        <p> 
          If you know or suspect that anyone other than you, knows your user identification code or password, you 
          must promptly notify us at{' '}
          <a href={`mailto:${INFO_EMAIL}`}>
            {INFO_EMAIL}
          </a>
        </p>

        <h2 id="how" className="tc-title uc-title">How You may use Material on our Site</h2>
        <p>
          We are the owner or the licensee of all intellectual property rights in our Site, and in the material 
          published on it. Those works are protected by copyright laws and treaties around the world. All such 
          rights are reserved.
        </p>
        <p>  
          You may print off one copy, and may download extracts, of any page(s) from our Site for your personal use and 
          you may draw the attention of others within your organisation to content posted on our Site.
        </p>  
        <p>
          You must not modify the paper or digital copies of any materials you have printed off or downloaded in any way, 
          and you must not use any illustrations, photographs, video or audio sequences or any graphics separately from 
          any accompanying text.
        </p>
        <p>  
          Our status (and that of any identified contributors) as the authors of content on our Site must always 
          be acknowledged.
        </p>
        <p>  
          You must not use any part of the content on our Site for commercial purposes without obtaining a licence to do 
          so from us or our licensors.
        </p>
        <p>  
          If you print off, copy or download any part of our Site in breach of these terms of use, your right to use our 
          Site will cease immediately and you must, at our option, return or destroy any copies of the materials you have made.
        </p>      
        
        <h2 id="information" className="tc-title uc-title">Do not Rely on Information on our Site</h2>
        <p>
          The content on our Site is provided for general information only. It is not intended to amount to advice on which 
          you should rely. You must obtain professional or specialist advice before taking, or refraining from, any action 
          on the basis of the content on our Site.
        </p>
        <p>  
          Although we make reasonable efforts to update the information on our Site, we make no representations, warranties 
          or guarantees, whether express or implied, that the content on our Site is accurate, complete or up to date.
        </p> 

        <h2 id="external" className="tc-title uc-title">We are not Responsible for Websites we Link to</h2>
        <p>
          Where our Site contains links to other sites and resources provided by third parties, these links are provided for 
          your information only. Such links should not be interpreted as approval by us of those linked websites or information 
          you may obtain from them.
        </p>
        <p>  
          We have no control over the contents of those sites or resources.
        </p>

        <h2 id="responsibility" className="tc-title uc-title">Our Responsibility for Loss or Damage Suffered by You</h2>
        <ul>
          <li>
            We do not exclude or limit in any way our liability to you where it would be unlawful to do so. This includes 
            liability for death or personal injury caused by our negligence or the negligence of our employees, agents or 
            subcontractors and for fraud or fraudulent misrepresentation.
          </li>
          <li>
            Different limitations and exclusions of liability will apply to liability arising as a result of the 
            supply of any services to you, which will be set out in our <a href="/zoom-lessons-terms-conditions">
            Terms and Conditions for Online Services</a>
          </li>
          <li>
            Please note that we only provide our Site for domestic and private use. You agree not to use our Site for any 
            commercial or business purposes, and we have no liability to you for any loss of profit, loss of business, 
            business interruption, or loss of business opportunity.
          </li>
          <li>
            If defective digital content that we have supplied, damages a device or digital content belonging to you and 
            this is caused by our failure to use reasonable care and skill, we will either repair the damage or pay you 
            compensation.
          </li>
        </ul>

        <h2 id="personal" className="tc-title uc-title">How we may use Your Personal Information</h2>
        <p>
          We will only use your personal information as set out in our <a href="/privacy-policy">Privacy Notice</a>
        </p>

        <h2 id="virus" className="tc-title uc-title">We are not responsible for Viruses and You must not introduce them</h2>
        <p>
          We do not guarantee that our Site will be secure or free from bugs or viruses.
        </p>
        <p>  
          You are responsible for configuring your information technology, computer programmes and platform to access 
          our Site. You should use your own virus protection software.
        </p>
        <p>  
          You must not misuse our Site by knowingly introducing viruses, trojans, worms, logic bombs or other material 
          that is malicious or technologically harmful. You must not attempt to gain unauthorised access to our Site, the 
          server on which our Site is stored or any server, computer or database connected to our Site. You must not 
          attack our Site via a denial-of-service attack or a distributed denial-of service attack. By breaching this 
          provision, you would commit a criminal offence under the Computer Misuse Act 1990. We will report any such 
          breach to the relevant law enforcement authorities and we will co-operate with those authorities by disclosing 
          your identity to them. In the event of such a breach, your right to use our Site will cease immediately.
        </p>

        <h2 id="links" className="tc-title uc-title">Rules about linking to our Site</h2>
        <p>
          You may link to our home page, provided you do so in a way that is fair and legal and does not damage our 
          reputation or take advantage of it.
        </p>
        <p>  
          You must not establish a link in such a way as to suggest any form of association, approval or endorsement 
          on our part where none exists.
        </p>  
        <p>
          You must not establish a link to our Site in any website that is not owned by you.
        </p>
        <p>  
          Our Site must not be framed on any other Site, nor may you create a link to any part of our Site other 
          than the home page.
        </p>
        <p> 
          We reserve the right to withdraw linking permission without notice.
        </p>  
        <p>
          If you wish to link to or make any use of content on our Site other than that set out above, please contact
          {" "}
          <a href={`mailto:${INFO_EMAIL}`}>
            {INFO_EMAIL}
          </a>
        </p>

        <h2 id="country" className="tc-title uc-title">Which Country's Laws apply to any Disputes</h2>
        <p>
          Please note that these terms of use, their subject matter and their formation, are governed by English law. 
          You and we both agree that the courts of England and Wales will have exclusive jurisdiction.
        </p>
      </div>
    </Container>
  </LayoutInner>
);

export default Page;
